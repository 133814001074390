const appConfig = {
    apiPrefix: '/api',
    t4API: 'https://gateway.cx.t4isb.com/api',//'127.0.0.1/api',//
    authenticatedEntryPath: '/app/crm/customers',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig
