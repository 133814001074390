import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import localStorage from 'redux-persist/es/storage'
import { PERSIST_STORE_NAME } from '../constants/app.constant'
import deepParseJson from '../utils/deepParseJson'

const unauthorizedCode = [401]

export const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
})

export const T4BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.t4API,
})

T4BaseService.interceptors.request.use(
    (config) => {
        let accessToken = 'Io6GbEDvatORJ7rg3mBuDrZDRfKoOWad'// 'Io6GbEDvatORJ7rg3mBuDrZDRfKoOWad'// '5d80e34577a7233ff5b22dc0ce8a8cce7eaf1a07c0aea69721d8cb1637039202'//persistData.auth.session.token

        // if (!accessToken) {
        //     const { auth } = store.getState()
        //     accessToken = auth.session.token
        // }

        // const { auth } = store.getState()
        // let accessToken = auth.session.token

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
                ] = accessToken
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

T4BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }

        return Promise.reject(error)
    }
)

BaseService.interceptors.request.use(
    (config) => {
        // const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        // const persistData = deepParseJson(rawPersistData)

        let accessToken = '89f2351d6729bcdc84c9f57aea666d69dfa139aa1584192d54017cd811250240'// '5d80e34577a7233ff5b22dc0ce8a8cce7eaf1a07c0aea69721d8cb1637039202'//persistData.auth.session.token

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.token
        }

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }

        return Promise.reject(error)
    }
)
